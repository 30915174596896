import algoliasearch from "algoliasearch/lite";
import cn from "classnames";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { useState } from "react";
import { Configure, InstantSearch } from "react-instantsearch";

import { availableLocales, Locale } from "../../settings/countries";
import { getWindow } from "../../utils/browser-features";
import BreedHits from "./BreedHits";
import BreedSearchBox from "./BreedSearchBox";

const gastbyAlgoliaAppId = process.env.GATSBY_ALGOLIA_APP_ID ?? "";
const gastbyAlgoliaSearchKey = process.env.GATSBY_ALGOLIA_SEARCH_KEY ?? "";

const searchClient = algoliasearch(gastbyAlgoliaAppId, gastbyAlgoliaSearchKey);

interface BreedSelectProps {
  species: string;
  selectBreedUuid: (uuid: string) => void;
  adoptionClassName?: string;
  preselectedBreedName?: string | null | undefined;
}

const BreedSelect = ({
  species,
  selectBreedUuid,
  adoptionClassName,
  preselectedBreedName,
}: BreedSelectProps): JSX.Element => {
  const [breedName, setBreedName] = useState(preselectedBreedName ?? "");
  const { i18n } = useTranslation();
  const language: Locale = availableLocales.includes(i18n.language as Locale)
    ? (i18n.language as Locale)
    : "es";

  const storeBreedNameInCache = (name: string): void => {
    getWindow()?.sessionStorage?.setItem("breedName", name);
  };

  const selectHit = (uuid: string, name: string): void => {
    selectBreedUuid(uuid);
    storeBreedNameInCache(name);
    setBreedName(name);
  };

  const toggleBreedHits = (): void => {
    document.documentElement.classList.toggle("is-breed-hits-open");
  };

  return (
    <section className={cn(adoptionClassName)}>
      <InstantSearch searchClient={searchClient} indexName={`breeds_${language}_prod`}>
        <Configure filters={`species:${species}`} />
        <BreedSearchBox breedName={breedName} toggleBreedHits={toggleBreedHits} />
        <BreedHits breedName={breedName} selectHit={selectHit} />
      </InstantSearch>
    </section>
  );
};

export default BreedSelect;
