import { StripeError } from "@stripe/stripe-js";

import {
  ComplianceError,
  NotFoundError,
  PromotionCodeError,
  UnprocessableEntityError,
} from "../../../../utils/error-utils/errors";
import { rollbar } from "../../../../utils/rollbar";

export const handleConfirmIntentStripeErrors = (err: StripeError, context: string): void => {
  const description = "confirmIntentStripe";

  rollbar.warn(`StripeError: ${err.type}`, err, {
    fingerprint: "stripe-error",
    context: `${description}/${context}`,
  });
};

export const handleCreateSubscriptionErrors = (err: Error, context: string): void => {
  const description = "createSubscription";

  if (err instanceof PromotionCodeError) {
    rollbar.warn(description, err, { fingerprint: "promotion-code-not-valid", context });
  } else if (err instanceof NotFoundError) {
    rollbar.warn(description, err, { fingerprint: "result-for-subscription-not-found", context });
  } else {
    rollbar.error(err.message, err, { context: `${description}/${context}` });
  }
};

export const handleCustomCheckoutSessionErrors = (err: Error, context: string): void => {
  const description = "customCheckoutSession";
  if (err instanceof ComplianceError) {
    rollbar.warn(description, err, { fingerprint: "compliance-validation", context });
  } else if (err instanceof UnprocessableEntityError) {
    rollbar.warn(description, err, {
      fingerprint: "custom-checkout-session-unprocessable-entity",
      context,
    });
  } else {
    rollbar.error(err.message, err, { context: `${description}/${context}` });
  }
};
