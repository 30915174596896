import { useField } from "formik";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { ReactNode } from "react";

import { maxPetAgeNumberOfYears, minPetAgeNumberOfWeeks } from "../../settings/pet";
import { getAgeRangeTranslationObject } from "../../utils/age";
import { getDateXDaysAgo, getDateXWeeksAgo, getDateXYearsAgo, getIsoDate } from "../../utils/date";
import Select from "../select/Select";

interface AgeSelectProps {
  label?: string;
  name: string;
  handleChange?: (age: string, birthDate: string) => void;
  adoptionClassName?: string;
  isLabelHidden?: boolean;
}

const birthDates: Date[] = [getDateXDaysAgo(1), getDateXWeeksAgo(minPetAgeNumberOfWeeks)];

for (let i = 1; i <= maxPetAgeNumberOfYears - 1; i++) {
  birthDates.push(getDateXYearsAgo(i));
}

birthDates.push(getDateXYearsAgo(maxPetAgeNumberOfYears));

const AgeSelect = ({
  label,
  name,
  handleChange,
  adoptionClassName,
  isLabelHidden,
}: AgeSelectProps): JSX.Element => {
  const [_field, meta, helpers] = useField<string>(name);

  const { setValue } = helpers;
  const { t } = useTranslation();

  const { value } = meta;

  const handleSelect = (age: string): void => {
    const birthDateTranslation = getAgeRangeTranslationObject(age);
    const birthDate = t(birthDateTranslation.key, { ...birthDateTranslation.params, lng: "en" });

    setValue(age, true);

    if (handleChange) {
      handleChange(age, birthDate);
    }
  };

  const options: { value: string; label: string }[] = birthDates.map((birthDate) => {
    const translation = getAgeRangeTranslationObject(birthDate.toISOString());

    return {
      value: getIsoDate(birthDate),
      label: t(translation.key, translation.params),
    };
  });

  const getLabelSelected = (): ReactNode | string => {
    if (value) {
      const { key, params } = getAgeRangeTranslationObject(value);

      // We use <i> instead of <span> because Reach adds crap to the first <span> that finds inside <button> :(
      return (
        <i className={"value-wrapper"}>
          <span className="listbox-label">{t(key, params)}</span>
        </i>
      );
    }

    return label;
  };

  return (
    <Select
      name={name}
      label={label}
      buttonLabel={getLabelSelected()}
      isLabelHidden={isLabelHidden}
      options={options}
      handleChange={handleSelect}
      adoptionClassName={adoptionClassName}
    />
  );
};

export default AgeSelect;
