import cn from "classnames";
import { Form, Formik, FormikHelpers, FormikProps } from "formik";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { forwardRef, ReactNode } from "react";
import * as Yup from "yup";

import AgeSelect from "../../../atoms/age-select/AgeSelect";
import Button from "../../../atoms/button/Button";
import Heading from "../../../atoms/heading/Heading";
import Select from "../../../atoms/select/Select";
import TextFormControl from "../../../atoms/text-form-control/TextFormControl";
import BreedSelect from "../../../molecules/breed-select/BreedSelect";
import { PetSpecies } from "../../../settings/pet";
import { Events, track } from "../../../utils/analytics";
import { handleUpdateResultErrors } from "../../../utils/error-utils/catch-error-handlers";
import { useResult, useUpdateResult } from "../../result/use-cases/result-use-cases";
import * as styles from "./PetInfoForm.module.scss";

interface PetInfoFormProps {
  sectionName: string;
  adoptionClassName?: string;
  onEditCancelled: () => void;
  onEditSubmitted: (values) => void;
}

export interface PetInfoFormValues {
  pet_name: string;
  pet_birth_date: string;
  pet_breed_kb_key: string;
  pet_sex: string;
}

interface SexOption {
  value: "male" | "female";
  label: string;
}

const petSpeciesIcon: { [key in keyof typeof PetSpecies]: string } = {
  cat: "cat1",
  dog: "dog1",
};

const PetInfoFormComponent = (
  { sectionName, adoptionClassName, onEditCancelled, onEditSubmitted }: PetInfoFormProps,
  ref: React.Ref<HTMLFormElement>
): JSX.Element => {
  const result = useResult();
  const { t } = useTranslation();
  const updateResult = useUpdateResult();

  const sexOptions: SexOption[] = [
    { value: "male", label: t("common.pet_sex.male") },
    { value: "female", label: t("common.pet_sex.female") },
  ];

  const handleCancel = (): void => {
    track(Events.TAPPED_ON_CANCEL_ACTION, {
      action: "Cancel edit info",
      section: sectionName,
    });

    onEditCancelled();
  };

  return (
    <>
      {result && (
        <Formik
          initialValues={{
            pet_name: result.pet_name,
            pet_birth_date: result.pet_birth_date,
            pet_breed_kb_key: result.pet_breed.kb_key,
            pet_sex: result.pet_sex,
          }}
          validationSchema={Yup.object({
            pet_name: Yup.string().required(t("common.validation.required")),
            pet_birth_date: Yup.string().required(t("common.validation.required")),
            pet_breed_kb_key: Yup.string().required(t("common.validation.required")),
            pet_sex: Yup.string().required(t("common.validation.required")),
          })}
          onSubmit={(
            values: PetInfoFormValues,
            { setSubmitting }: FormikHelpers<PetInfoFormValues>
          ) => {
            track(Events.TAPPED_ON_SAVE_ACTION, {
              action: "Save edit info",
              section: sectionName,
            });

            if (!updateResult) {
              return;
            }

            setSubmitting(true);

            updateResult(values)
              .then((): void => {
                onEditSubmitted(values);
              })
              .catch((err: Error) => {
                handleUpdateResultErrors(err, "PetInfoForm");
                alert(t("landing.error_message"));
                setSubmitting(false);
              });
          }}
        >
          {(props: FormikProps<PetInfoFormValues>) => {
            const { isSubmitting, isValid, setFieldValue, values, dirty } = props;

            const getSexLabelSelected = (): ReactNode => {
              if (values.pet_sex) {
                const { label } =
                  sexOptions.find((option) => option.value === values.pet_sex) || {};

                return (
                  <i className={"value-wrapper"}>
                    <span className="listbox-label">{label}</span>
                  </i>
                );
              }
            };

            return (
              <Form className={cn(styles.petInfoForm, adoptionClassName)} ref={ref}>
                <svg
                  className={cn(styles.icon, `icon--${petSpeciesIcon[result.pet_species]}`)}
                  aria-hidden="true"
                >
                  <use href={`#icon-${petSpeciesIcon[result.pet_species]}`}></use>
                </svg>

                <Heading level={2} adoptionClassName={cn(styles.title)}>
                  {t("details.pet_data.title", { petName: result.pet_name })}
                </Heading>

                <Button
                  adoptionClassName={styles.save}
                  type={"submit"}
                  disabled={isSubmitting || !isValid || !dirty}
                  isLoading={isSubmitting}
                >
                  {t("common.save")}
                </Button>

                <Button
                  adoptionClassName={styles.cancel}
                  type={"button"}
                  onClick={handleCancel}
                  disabled={isSubmitting}
                >
                  {t("common.cancel")}
                </Button>

                <TextFormControl
                  label={t("details.pet_data.name.field.title")}
                  name={"pet_name"}
                  adoptionClassName={styles.petName}
                />

                <AgeSelect
                  handleChange={(e: string) => setFieldValue("pet_birth_date", e)}
                  name={"pet_birth_date"}
                  adoptionClassName={styles.ageSelect}
                />

                <BreedSelect
                  adoptionClassName={cn(styles.breedSelect)}
                  species={result.pet_species}
                  selectBreedUuid={(e: string) => setFieldValue("pet_breed_kb_key", e)}
                  preselectedBreedName={result.pet_breed.name}
                />

                <Select
                  name={"pet_sex"}
                  buttonLabel={getSexLabelSelected()}
                  handleChange={(e: string) => setFieldValue("pet_sex", e)}
                  adoptionClassName={styles.sexSelect}
                  options={sexOptions}
                />
              </Form>
            );
          }}
        </Formik>
      )}
    </>
  );
};

export default forwardRef(PetInfoFormComponent);
