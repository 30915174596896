import { useResultUuid } from "../../../organisms/result/use-cases/result-use-cases";
import { customCheckoutSession } from "../data/result-repository";

export const useCustomCheckoutSession = (): (() => Promise<unknown>) => {
  const resultUuid = useResultUuid();

  if (!resultUuid) {
    return () => Promise.resolve();
  }

  return async (): Promise<unknown> => {
    return customCheckoutSession(resultUuid);
  };
};
